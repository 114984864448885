import Request from '@/utils/request'

const HomeApi = {
  // 首页普通商品列表
  getGoodslist (data) {
    return Request('/home/index/goodslist', 'get', data)
  },
  // 首页特殊活动商品列表
  getSpecialGoods (data) {
    return Request('/home/index/specialgoods', 'get', data)
  },
  // 用户剩余可用的免单次数
  userSnum (data) {
    return Request('/home/index/snum', 'get', data)
  },
  // 商品转链
  getGoodsKl (data) {
    return Request('/home/index/zl', 'get', data)
  }
}

export default HomeApi