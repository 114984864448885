<template>
    <div class="main-container">
        <div class="header-tab">
            <van-tabs v-model="active" @change="tabChange">
                <van-tab v-for="(item,index) in orderTabs" :key="index" :title="item" color="#808080"></van-tab>
            </van-tabs>
        </div>

        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getOrderList"
            >
            <div class="order-list-query-goods">
                <div class="order-info" v-for="(item,index) in orderList" :key="index">
                    <div class="order-status-wrap">
                        <div class="order-number">订单号：{{ item.orderid }}</div>
                        <div class="status-con">{{ orderTabs[item.fan_status] }}</div>
                    </div>

                    <div class="goods-main">
                        <img class="picture" :src="item.product_img" alt="">
                        <div class="goods-right-box">
                            <div class="goods-name">{{ item.getOrderList }}</div>
                            <div class="price-main-box">
                                <div class="price-item">
                                    <p class="price-num">￥<span>{{ item.total_pay_amount }}</span></p>
                                    <p class="price-name">支付金额</p>
                                </div>
                                <div class="price-item">
                                    <p class="price-num">￥<span>{{ item.fan_amount }}</span></p>
                                    <p class="price-name">预估返</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="order-pay-time">支付时间：{{ formatDate(item.pay_success_time) }}</div>
                </div>
            </div>

            <template v-if="orderList.length === 0">
                <noDtaTip />
            </template>
        </van-list>

        <!-- <div class="finish-text" v-if="orderList.length>0 && !noDataTop">到底了啦,没有更多数据了!</div> -->
    </div>
</template>
<script>
    import OrderApi from '@/api/order'
    import Vue from 'vue'
    import { Tab, Tabs, List } from 'vant'
    import noDtaTip from '@/components/NoDataTip.vue'
    import { formatDate } from '@/utils'
    Vue.use(Tab)
    Vue.use(Tabs)
    Vue.use(List)
    export default {
         name: 'myOrder',
        data() {
            return {
                active: 0,
                orderTabs: ['全部', '即将到账', '已到账', '已失效', '已维权'],
                finished:false,
                loading:false,
                orderList: [],
                loadingNum: 1,
                totalPage: 1,
                noDataTop: false,
            }
        },
        components: {
            'noDtaTip': noDtaTip
        },
        methods: {
            tabChange () {
                this.loadingNum = 1
                this.totalPage = 1
                this.orderList=[]
                this.getOrderList()
            },
            getOrderList() {
                if (!localStorage.token) return
                this.loading = true
                if(this.loadingNum > this.totalPage){
                    this.finished=true
                    this.loading=false
                    return
                }

                OrderApi.getOrderlist({
                    status: this.active.toString(),
                    p: this.loadingNum
                }).then(res => {
                    this.orderList=[
                        ...this.orderList,
                        ...res.data
                    ]
                    this.totalPage = res.page.totalpage
                    this.loadingNum++
                    this.loading=false
                })
            },
            formatDate(val) {
                return formatDate(val)
            }
        }
    }

</script>
<style scoped lang="less">
    .main-container {
        min-height: 100vh;
        background-color: #f5f5f5;
        padding-top: 94px;
        padding-bottom: 100px;
        .header-tab {
            width: 750px;
            height: 94px;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 100;
        }
        /deep/ .van-tabs__wrap {
            height: 94px;
            bottom: initial;
            .van-tabs__nav {
                background-color: #f5f5f5;
                padding-bottom: 0;
                .van-tab {
                    font-size: 30px;
                    &.van-tab--active {
                        color: #1a1a1a;
                        font-size: 32px;
                    }
                }
            }
        }
        /deep/ .van-tabs__line {
            width: 40px;
            height: 6px;
            background: #ff3c56;
            border-radius: 4px;
            bottom: 15px;
        }

        .order-list-query-goods {
            margin-top: 16px;
            padding: 0 24px;
            .order-info {
                width: 702px;
                height: 372px;
                background: #fff;
                border-radius: 24px;
                padding: 24px;
                box-sizing: border-box;
                margin-top: 16px;
                .order-status-wrap {
                    font-size: 28px;
                    display: flex;
                    justify-content: space-between;
                    .order-number {
                        color: #808080;
                    }
                    .status-con {
                        color: #ff3c56;
                    }
                }
                .goods-main {
                    margin-top: 24px;
                    padding-bottom: 24px;
                    border-bottom: 1px solid #f5f5f5;
                    display: flex;
                    .picture {
                        width: 176px;
                        height: 176px;
                        border-radius: 16px;
                        margin-right: 24px;
                    }
                    .goods-right-box {
                        flex: 1;
                        overflow: hidden;
                        .goods-name {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            color: #1a1a1a;
                            font-size: 28px;
                        }

                        .price-main-box {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin-top: 16px;
                            height: 114px;
                            background: rgba(255, 60, 86, 0.2);
                            border-radius: 16px;
                            position: relative;
                            &::before {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 2px;
                                height: 30px;
                                background: rgba(255, 60, 86, 0.2);
                                border-radius: 32px;
                                transform: translate(-50%, -50%);
                            }

                            .price-item {
                                width: 50%;
                                text-align: center;
                                .price-num {
                                    color: #ff3c56;
                                    font-size: 24px;
                                    span {
                                        font-size: 32px;
                                    }
                                }
                                .price-name {
                                    color: #1a1a1a;
                                    font-size: 24px;
                                }
                            }
                        }
                    }
                }
                .order-pay-time {
                    margin-top: 24px;
                    color: #808080;
                    font-size: 24px;
                }
            }
        }
        .finish-text {
            width: 750rpx;
            text-align: center;
            font-size: 24px;
            text-align: center;
            padding-top: 24px;
            color: #b2b2b2;
        }
    }
</style>
