<template>
    <div class="goods-top-item" @click="goodsClick">
        <div class="left">
            <img class="img" :src="goods.product_img" alt="">
        </div>
        <div class="right">
            <div class="name">
                {{goods.product_name}}
            </div>
            <div class="percentage">
                <van-progress pivot-text="" color="#FF3C56" stroke-width="8" :percentage="90"/>
            </div>
            <div class="goods-num">
                库存{{goods.reserve}}单
            </div>
            <div class="price-content">
                <div class="price-left">
                    <div><span class="present-price">￥0</span> <span
                            class="original-price">{{goods.product_price}}</span></div>
                    <div class="desc">
                        先付￥{{goods.product_price}}，后返￥{{goods.product_price}}
                    </div>
                </div>
                <div class="price-right"><img class="purchase-btn" src="@/assets/img/index/purchase_btn.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Progress} from 'vant';

    export default {
        name: "goods-top-item",
        components: {
            [Progress.name]: Progress,
        },
        props: {
            goods: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        computed: {
            percentage: function () {
                let num = parseFloat(this.goods.residueNum / this.goods.goodsNum)
                return (num * 100).toFixed(2)
            }
        },
        methods:{
            goodsClick(){
                this.$emit('goodsClick',this.goods)
            }
        }

    }
</script>

<style lang="less" scoped>
    .goods-top-item {
        display: flex;
        width: 702px;
        height: 252px;
        background: #fff;
        border-radius: 24px;
        margin: 16px auto 0;
        padding: 16px;

        .left {
            .img {
                width: 220px;
                height: 220px;
                border-radius: 16px;
            }
        }

        .right {
            margin-left: 16px;

            .name {
                max-width: 392px;
                height: 40px;
                line-height: 44px;
                font-size: 28px;
                font-weight: bold;
                color: #1A1A1A;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1; /*行数*/
                -webkit-box-orient: vertical;
            }

            .percentage {
                margin-top: 16px;
            }

            .goods-num {
                font-size: 24px;
                color: #B2B2B2;
                margin-top: 16px;
            }

            .price-content {
                display: flex;
                height: 80px;
                background: #FFF0F1;
                border-radius: 40px;

                margin-top: 18px;

                .price-left {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 260px;
                    padding-left: 24px;
                    box-sizing: border-box;

                    .present-price {
                        font-size: 24px;
                        font-weight: 700;
                        color: #FF3C56;
                    }

                    .original-price {
                        font-size: 20px;
                        font-weight: 400;
                        color: rgba(255, 60, 86, 0.5);
                        text-decoration: line-through;
                    }

                    .desc {
                        font-size: 20px;
                        font-weight: 500;
                        color: #FF3C56;
                        margin-top: 4px;
                        transform: scale(.8);
                        zoom: 1;
                        -moz-transform-origin: 0 0;
                        -webkit-transform-origin: 0 0;
                        -o-transform-origin: 0 0;
                        width: 350px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1; /*行数*/
                        -webkit-box-orient: vertical;

                    }
                }

                .price-right {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    flex: 0 0 162px;

                    .purchase-btn {
                        width: 162px;
                        height: 80px;
                    }
                }
            }

        }
    }
</style>
