// 函数节流
let isPending = false
export const throttle = (callback, delay = 2000) => {
  return function () {
    // 清除待执行的定时器任务
    if (isPending) return
    // 保存this和arguments
    const that = this
    const args = arguments
    //立即调用
    callback.apply(that, args)
    // 每隔delay的时间, 启动一个新的延迟定时器, 去准备调用callback
    isPending = setTimeout(function () {
      isPending = null
    }, delay)
  }
}

// 过滤请求参数
export const filterParams = (obj) => {
    if (!obj) {
      return {}
    }
  
    const newObj = JSON.parse(JSON.stringify(obj))
  
    for (let key in newObj) {
      if (newObj[key] === null) {
        delete newObj[key]
      }
      if (valueType(newObj[key]) === 'String') {
        newObj[key] = newObj[key].trim()
      } else if (valueType(newObj[key]) === 'Object') {
        newObj[key] = filterParams(newObj[key])
      }
    }
    return newObj
  }

  /**
 * 判断值类型
 * @param val
 * @returns {boolean}
 */
export const valueType = (val) => {
    const type = {
      '[object Object]': 'Object',
      '[object Array]': 'Array',
      '[object Undefined]': 'Undefined',
      '[object Null]': 'Null',
      '[object String]': 'String',
      '[object Number]': 'Number',
      '[object Boolean]': 'Boolean',
    }
    const valType = type[Object.prototype.toString.call(val)]
    return valType
  }

  // 转时间格式：yyyy-MM-dd hh:mm:ss
  export const formatDate = (timestamp) => {
      let date = new Date(parseInt(timestamp) * 1000);
      let Year = date.getFullYear();
      let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
      let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
      let Hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
      let Minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
      let Sechond = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
      return Year + '-' + Moth + '-' + Day + ' '+ Hour +':'+ Minute  + ':' + Sechond; 
  }

  // 获取url的params
export const getUrlID = (url, key) => {
  const reg = new RegExp(`${key}=+.*&?`, 'g')
  let idStr = url.match(reg) ? url.match(reg)[0] : ''

  if (idStr && idStr.indexOf(key) !== -1) {
    idStr = idStr.replace(key + '=', '')
  }

  if (idStr.indexOf('&') !== -1) {
    const endIndex = idStr.indexOf('&')
    idStr = idStr.slice(0, endIndex)
  }
  return idStr
}

export const base64Decode = (str) => {
  return decodeURIComponent(atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}