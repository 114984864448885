<template>
    <div class="main-container">
        <div class="may-balance-box">
            <p class="title">可提现金额</p>
            <div class="balance">￥<span>{{ amount / 100 }}</span></div>
            <button type="button" class="btn-withdraw" @click="clickJump('withDraw')">立即提现</button>
            <p class="into-withdraw" @click="clickJump('record')">提现记录</p>
        </div>
        <div class="affirm-goods-box">
            <div class="affir-item">
                <p class="label-name">确认收货待到账</p>
                <p class="label-value">￥<span>{{ paysucc / 100 }}</span></p>
            </div>
            <div class="affir-item">
                <p class="label-name">待确认收货</p>
                <p class="label-value">￥<span>{{ confirm / 100 }}</span></p>
            </div>
            <p class="auth-desc">未授权立返的用户，收益会在订单确认收货后16天到账余额</p>
            <button type="button" class="auth-btn">
                <span>去授权立返</span>
                <img class="next-icon" src="@/assets/img/dyFreeOrder/grzx_icon_more.png" />
            </button>
        </div>
    </div>
</template>
<script>
    import WithdrawApi from '@/api/withdraw'
    // import Vue from 'vue'
    export default {
        name: 'myWallet',
        data() {
            return {
                amount: 0,
                paysucc: 0,
                confirm: 0,
                goodsImg: 'https://mimg.gaoyong2021.com/static/pro-mini-gylm/public/test_goods.png'
            }
        },
        created() {
            localStorage.token && this.getAmount()
        },
        methods: {
            clickJump(op) {
                let query = ''
                switch (op) {
                    // 提现
                    case 'withDraw':
                        query = { path: '/withdraw', query: {} }
                        break;
                    // 提现记录
                    case 'record':
                        query = { path: '/withdrawRecord', query: {} }
                        break;
                    default:
                        break;
                }
                this.$router.push(query)
            },
            getAmount() {
                WithdrawApi.getAmount().then(res => {
                    this.amount = res.data.amount
                    this.paysucc = res.data.paysucc
                    this.confirm = res.data.confirm
                })
            }
        },
    }

</script>
<style scoped lang="less">
    .main-container {
        min-height: 100vh;
        background-color: #f5f5f5;
        padding: 16px 24px 24px;
        box-sizing: border-box;
        padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
        padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
        background-image: url('~@/assets/img/dyFreeOrder/tx_bg.png');
        background-repeat: no-repeat;
        background-size: 100% 300px;
        .may-balance-box {
            width: 702px;
            height: 476px;
            background: #fff;
            border-radius: 24px;
            margin: 16px auto auto;
            text-align: center;
            padding-top: 48px;

            .title {
                color: #1a1a1a;
                font-size: 28px;
            }
            .balance {
                margin-top: 16px;
                font-size: 56px;
                font-weight: bold;
                span {
                    font-size: 88px;
                }
            }
            .btn-withdraw {
                width: 412px;
                height: 88px;
                background: #ff3c56;
                border-radius: 58px 58px 58px 58px;
                font-size: 34px;
                color: #fff;
                margin: 56px auto auto;
            }
            .into-withdraw {
                margin-top: 24px;
                color: #1a1a1a;
                font-size: 28px;
            }
        }
        .affirm-goods-box {
            width: 702px;
            height: 418px;
            background: #fff;
            border-radius: 24px;
            margin: 16px auto auto;
            padding: 0 24px;
            box-sizing: border-box;
            .affir-item {
                height: 112px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 0.5px solid #d8d8d8;
                .label-name {
                    color: #3d3d3d;
                    font-size: 28px;
                }
                .label-value {
                    font-weight: bold;
                    color: #1a1a1a;
                    font-size: 24px;
                    span {
                        font-size: 34px;
                    }
                }
            }
            .auth-desc {
                color: #808080;
                font-size: 24px;
                margin-top: 32px;
            }
            .auth-btn {
                width: 234px;
                height: 72px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 140px;
                border: 0.7px solid #b2b2b2;
                color: #1a1a1a;
                font-size: 28px;
                margin: 24px auto auto;
                background-color: transparent;
                transform: rotateZ(360deg);
                .next-icon {
                    width: 20px;
                    height: 20px;
                    margin-left: 10px;
                    display: block;
                }
            }
        }
    }
</style>
