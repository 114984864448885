<template>
  <router-view v-if="isReload" />
</template>

<script>
import { wxLogin } from "@/utils/wxLogin";
export default {
  name: "App",
  data() {
    return {
      isReload: true
    };
  },
  provide() {
    return {
      reload: this.reload
    };
  },
  methods: {
    reload() {
      this.isReload = false;
      this.$nextTick(() => {
        this.isReload = true;
      });
    }
  },
  created() {
    wxLogin();
  }
};
</script>

