import axios from 'axios'
import Qs from 'qs'
import { Toast } from 'vant';
import { throttle, filterParams } from "./index";

 // const DefaultUrl = 'https://test.huimai88.com'

 const DefaultUrl = 'https://zt-api.gaoyong2026.com'

export const requestQue={}

// 创建axios实例
const request = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: DefaultUrl,
  // 超时
  timeout: 1000 * 60,
})

// request拦截器
request.interceptors.request.use(
  config => {
    config.headers['x-token'] = localStorage.token

    return config
  },
  error => {
    Promise.reject(error)
  }
)

// 响应拦截器
request.interceptors.response.use(
  res => {
    if (requestQue[res.config.url]){
      delete requestQue[res.config.url]
    }
    const status = res.status
    const data = res.data
    const code = data.code || 0
    if (status !== 200 && status !== 304) {
      Toast(data.msg)
      return Promise.reject(res)
    }
    if (code === 101) {
      reLogin(data.msg)
    } else if (code !== 200) {
      tip(data.msg)
      return Promise.reject(res)
    } else {
      return data
    }
  },
  error => {
    const tip = function () {
      Toast(error.message)
    }
    throttle(tip, 5000)()
    return Promise.reject(error)
  }
)

export const Request = (url, methods, params, type) => {
  request.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
  // 过滤处理参数
  const query = {
    url: url,
    method: methods
  }
  if (methods === 'get') {
    query.params = filterParams(params)
  } else {
    //当有type参数时表示formdata类型数据
    if (type === 'formData') {
      request.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      query.data = Qs.stringify(filterParams(params))
    } else {
      query.data = filterParams(params)
    }
  }
  return request(query)
}

const tip =throttle( function (val) {
  Toast(val || '请求错误')
},5000)

const reLogin = throttle(function(val) {
  localStorage.token = ''
  Toast(val || '登陆过期')
  setTimeout(() => {
    window.location.replace('/')
  }, 300)
}, 5000)

export default Request
