import { getUrlID, base64Decode } from './index'

export const wxLogin = () => {
    if (localStorage.token) {
        // 本地存储token, 用户已经登录
        return
    }
    const url = window.location.href
    const info = getUrlID(url, 'info')
    const tuid = getUrlID(url, 'tuid')
   
    if (!info) {
         // const jumpUrl = `https://test.huimai88.com/user/user/silencelogin/tuid/${tuid}`
         const jumpUrl = `https://zt-api.gaoyong2026.com/user/user/silencelogin/tuid/${tuid}`
        window.location.replace(
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf3820078ad8ce098&redirect_uri=${encodeURIComponent(
            jumpUrl
        )}&response_type=code&scope=snsapi_base#wechat_redirect`
        );
    } else {
        const code = decodeURIComponent(info)
        const res = JSON.parse(base64Decode(code))
        // localStorage.info = info
        localStorage.token = res.token
        localStorage.phone = res.mobile

    }
}