<template>
    <div class="goods-item" @click="goodsClick">
        <img class="img" :src="goods.product_img" alt="">
        <div class="main">


            <div class="title-wrap">
                <div class="coupon-price" v-if="goods.couponPrice>0">{{goods.couponPrice}}元券</div>
                <div class="goods-title">
                    {{goods.product_name}}
                </div>
            </div>

            <div class="volume">
                <!-- 月销{{goods.volume}} |  -->
                剩余{{goods.reserve}}
            </div>

            <div class="btn-wrap">
                <div class="price-left">
                    <div class="original-price" >
                        <span v-if="goods.product_price_origin">￥{{goods.product_price_origin}}</span>
                        </div>
                    <div class="present-price">￥<span class="price">{{goods.product_price}}</span></div>
                </div>
                <div class="btn-right">
                    立即购买
                </div>
            </div>

        </div>

    </div>
</template>

<script>

    export default {
        name: "goods-item",
        props: {
            goods: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        methods:{
            goodsClick(){
                this.$emit('goodsClick',this.goods)
            }
        }
    }
</script>

<style lang="less" scoped>
    .goods-item {
        width: 342px;
        height: 558px;
        background: #fff;
        border-radius: 24px;
        margin-top: 18px;

        .img {
            width: 342px;
            height: 342px;
            border-radius: 24px 24px 0 0;
        }

        .main {
            padding: 16px;

            .title-wrap {
                display: flex;
                align-items: center;

                font-size: 28px;
                color: #1A1A1A;


                .coupon-price {
                    border-radius: 8px;
                    border: 1px solid rgba(255, 60, 86, 0.3);
                    font-size: 24px;
                    color: #FF3C56;
                    display: table;
                    padding: 0 8px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .goods-title {
                    flex: 1;
                    margin-left: 8px;
                    width: 200px;
                    font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
            .volume {
                font-size: 24px;
                font-weight: 400;
                color: #B2B2B2;
                margin-top: 16px;
            }
            .btn-wrap {
                display: flex;
                justify-content: space-between;
                margin-top: 30px;
                .price-left {
                    display: flex;
                    flex-direction: column;
                    height: 64px;
                    justify-content: flex-end;
                    .original-price {
                        font-size: 24px;
                        font-weight: 400;
                        color: #B2B2B2;
                        text-decoration: line-through;
                    }
                    .present-price {
                        font-size: 24px;
                        font-weight: 500;
                        color: #FF3C56;
                        font-weight: 700;
                        .price {
                            font-size: 32px;
                        }
                    }
                }
                .btn-right {
                    width: 144px;
                    height: 64px;
                    background: #FF3C56;
                    border-radius: 33px;
                    font-size: 24px;
                    font-weight: 600;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

            }
        }


    }
</style>
