<template>
    <div :class="['no-data-wrap', theme]">
        <div class="no-data-tip">
            <slot>{{text}}</slot>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {};
        },
        props: {
            theme: {
                type: String,
                default: ""
            },
            text: {
                type: String,
                default: "暂无数据"
            }
        },
        watch: {},
        mounted() {},
        methods: {}
    }
</script>

<style lang="less" scoped>
    .no-data-wrap {
        display: flex;
        display: -webkit-flex;
        -webkit-flex-flow: column;
        flex-flow: column;
        flex-direction: column;
        padding-top: 35%;
        height: 100%;

        &.big-theme {
            display: block;
            border-radius: 20px;
            padding: 36px;
            height: auto;
            text-align: center;
            background: #fff;

            .no-data-tip {
                margin: 0 auto;
                padding-top: 340px;
                height: 427px;
                font-size: 26px;
                color: #AFB1B3;
                line-height: 1.5;
                text-align: center;
                background: url(~@/assets/img/no-data-big.png) no-repeat center top;
                background-size: 428px 427px;
            }
        }

        &.list-empty-light {
            padding-top: 100px;
            padding-bottom: 100px;
            .no-data-tip {
                padding-top: 438px;
                font-size: 0;
                color: #d8d8d8;
                text-align: center;
                background: url(~@/assets/img/NoDataTip/list_empty_light.png) no-repeat center top;
                background-size: 438px 438px;
            }
        }
        &.list-empty-dark {
            padding-top: 100px;
            padding-bottom: 100px;
            .no-data-tip {
                padding-top: 438px;
                font-size: 0;
                color: #d8d8d8;
                text-align: center;
                background: url(~@/assets/img/NoDataTip/list_empty_dark.png) no-repeat center top;
                background-size: 438px 438px;
            }
        }
        .no-data-tip {
            padding-top: 299px;
            font-size: 30px;
            color: #d8d8d8;
            text-align: center;
            background: url(~@/assets/img/no-data-tip.png) no-repeat center top;
            background-size: contain;
        }

    }
</style>
