<template>
    <div class="index">
        <div class="header">
            <div class="content">
                <ul>3天内可拍7单</ul>
                <ul>今日我能拍 <span class="strong">{{ snum }}</span>单</ul>
            </div>
        </div>
        <div class="body">
            <div class="way-select-wrap">
                <ul class="title">
                    我的返利方式
                </ul>
                <div class="right">
                    <ul class="item" :class="{'active':waySelect==1}" @click="waySelectClick(1)">
                        16日返
                    </ul>
                    <ul class="item" :class="{'active':waySelect==2}" @click="waySelectClick(1)">
                        支付立返
                    </ul>
                </div>
            </div>

            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="getData"
            >
                <div v-for="(item,index) in goodsTopList" :key="index">
                    <goods-top-item :goods="item" @goodsClick="goodsClick"></goods-top-item>
                </div>

                <div class="goods-list">
                    <div v-for="(item,index) in goodsList" :key="index">
                        <goods-item :goods="item" @goodsClick="goodsClick"></goods-item>
                    </div>
                </div>
            </van-list>

        </div>

    </div>
</template>

<script>
    import goodsTopItem from './components/goods-top-item'
    import goodsItem from './components/goods-item'
    import {Toast, List} from 'vant';
    import Vue from 'vue'
    import VueClipboard from 'vue-clipboard2'
    Vue.use(VueClipboard)
    import HomeApi from "@/api/home";


    export default {
        name: "homeIndex",
        components: {
            goodsTopItem,
            goodsItem,
            [Toast.name]: Toast,
            [List.name]: List,
        },
        data() {
            return {
                snum: 0,
                waySelect: 1,
                finished:false,
                loading:false,
                loadingNum:1,
                totalPage: 1,
                goodsList:[],
                goodsTopList: [],
                topNum: 1,
                topTotalPage: 1
            }
        },
        created() {
            localStorage.token && this.getUserSnum()
        },
        methods: {
            waySelectClick(index) {
                this.waySelect = index
            },
            goodsClick(goods) {
                HomeApi.getGoodsKl({id: goods.id}).then(res => {
                    this.$copyText(res.data).then(
                        () => {
                            Toast('口令已复制，轻打开抖音下单')
                        },
                        err => {
                            Toast('复制失败,err:' + err)
                        }
                    )
                })
            },
            getData(){
                //todo:
                if (!localStorage.token) return
                this.loading=true
                if(this.loadingNum>this.totalPage){
                    this.finished=true
                    this.loading=false
                    return
                } 

                if (this.topNum <= this.topTotalPage) {
                    // 先加载特殊商品列表
                    this.getSpecialGoods()
                } else {
                    // 再加载普通商品列表
                    this.getGoodsList()
                }

            },
            getSpecialGoods() {
                HomeApi.getSpecialGoods({p: this.topNum}).then(res => {
                    this.goodsTopList = [
                        ...this.goodsTopList,
                        ...res.data
                    ]
                    this.topTotalPage = res.page.totalpage
                    this.topNum++
                    this.loading=false
                })
            },
            getGoodsList() {
                HomeApi.getGoodslist({p: this.loadingNum}).then(res => {
                    this.goodsList=[
                        ...this.goodsList,
                        ...res.data
                    ]
                    this.totalPage = res.page.totalpage
                    this.loadingNum++
                    this.loading=false
                })
            },
            getUserSnum() {
                HomeApi.userSnum().then(res => {
                    this.snum = res.data
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    @width: 702px;
    .index {
        background: #F5F5F5;
        min-height: 100vh;
        padding-bottom: 80px;

        .header {
            width: 750px;
            height: 540px;
            background: url("@/assets/img/index/banner.png") no-repeat;
            background-size: 100%;
            display: flex;
            justify-content: center;
            position: relative;

            .content {
                width: @width;
                height: 72px;
                position: absolute;
                bottom: 48px;
                background: rgba(166, 4, 56, 0.5);
                border-radius: 48px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 24px;
                box-sizing: border-box;
                color: #fff;

                ul {
                    display: flex;
                    align-items: center;

                    .strong {
                        font-size: 24px;
                        color: #FF3C56;
                        width: 32px;
                        height: 32px;
                        background: #fff;
                        border-radius: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0 8px;
                    }
                }
            }
        }

        .body {
            width: 750px;
            background: #F5F5F5;
            border-radius: 32px 32px 0 0;
            min-height: 300px;
            position: relative;
            top: -30px;
            padding-top: 24px;

            .way-select-wrap {
                width: @width;
                height: 112px;
                background: #fff;
                border-radius: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px 24px;
                box-sizing: border-box;
                margin: 0 auto;

                .title {
                    font-size: 32px;
                    font-weight: 700;
                    color: #1A1A1A;
                }

                .right {
                    display: flex;

                    .title {
                        flex: 1;
                    }

                    .item {
                        width: 214px;
                        height: 80px;
                        background: #F5F5F5;
                        border-radius: 16px;
                        font-size: 28px;
                        font-weight: 400;
                        color: #1A1A1A;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 18px;
                        border: 1px solid #F5F5F5;

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    .active {
                        background: #FFF0F1;
                        border-radius: 16px;
                        border: 1px solid #FF3C56;
                        color: #FF3C56;
                    }
                }

            }

            .goods-list {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width: @width;
                margin: 0 auto;
            }
        }
    }

</style>
