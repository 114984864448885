import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index'

Vue.use(VueRouter)

// 自动扫描 modules 里面的路由模块，路由模块请根据业务自行拆分
const files = require.context('./modules', false, /\.js$/)
const routes = []
// 获取所有的路由内容
files.keys().forEach(key => {
    const file = files(key).default
    // 根据导出的内容判断是否数组，如果数组需使用扩展运算符
    if (Array.isArray(file)) {
        routes.push(...file)
    } else {
        routes.push(file)
    }
})


routes.push(
    ...[
        {
            path: '/',
            name: 'Index',
            component: Index
        },{
            path: '/withdrawRecord',
            name: 'withdrawRecord',
            component: () => import('../views/withdrawRecord/index.vue')
        },
        {
            path:'/order',
            name:'order',
            meta:{title:'订单'},
            component:()=> import('../views/orders/index')
        },
        {
            path:'/my-wallet',
            name:'wallet',
            meta:{title:'提现'},
            component:()=> import('../views/my-wallet/index')
        },
        {
            path:'/withdraw',
            name:'withdraw',
            meta:{title:'提现'},
            component:()=> import('../views/withdraw/index')
        },
        {
            path:'/accountList',
            name:'accountList',
            meta:{title:'账号列表'},
            component:()=> import('../views/withdraw/accountList')
        }
    ]
)
// 一定要保证此路由在最底部!!!，否则会拦截到正常页面的路由
routes.push(
    {
        path: '*',
        name: '404',
        meta: {
            title: '未找到页面...'
        },
        component: () => import('../views/404')
    }
)
const router = new VueRouter({
    mode:  "hash",
    routes
})

export default router
