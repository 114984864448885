<template>
    <div>
        <van-tabs v-model="active" @click="tabsClick" line-width="0">
            <van-tab v-for="(item,index) in tabs" :key="index" :title="item.name">
                <home v-show="active==0"></home>
                <orders v-show="active ==1"></orders>
                <wallet v-show="active ==2"></wallet>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import {Tab, Tabs} from 'vant';
    import home from './home'
    import orders from './orders'
    import wallet from './my-wallet'
    // import withdraw from './withdraw'

    export default {
        name: "choicenessIndex",
        components: {
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            home,
            orders,
            wallet
        },
        data() {
            return {
                active: 0,
                tabs: [
                    {
                        name: '首页'
                    },
                    {
                        name: '订单'
                    },
                    {
                        name: '提现'
                    }
                ]
            }
        },
        methods: {
            tabsClick(name, title) {
                this.active = name
                // console.log(name, 'name')
            
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .van-tabs__wrap {
            position: fixed;
            bottom: 0;
            width: 100%;
            z-index: 1000;
    }

    /deep/ .van-tab--active {
        font-weight: 700;
    }
</style>
