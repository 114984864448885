import Request from '@/utils/request'

const WithdrawApi = {
  // 金额信息
  getAmount (data) {
    return Request('/home/usercenter/amount', 'get', data)
  },
  // 已绑定的收款账号
  getAccount (data) {
    return Request('/home/withdraw/account', 'get', data)
  },
  // 请求短信验证码
  getVerifycode (data) {
    return Request('/user/user/verifycode', 'get', data)
  },
  // 绑定/换绑 手机号
  bindMobile (data) {
    return Request('/home/usercenter/mobile', 'post', data)
  },
  // 绑定收款账号
  bindAccount (data) {
    return Request('/home/withdraw/bind', 'post', data)
  },
  // 申请提现
  doWithdraw (data) {
    return Request('/home/withdraw/dowithdraw', 'post', data)
  },
  // 提现记录
  withdrawRecords (data) {
    return Request('/home/withdraw/record', 'get', data)
  },
   // 用户所有收款账号
   getAccountlist (data) {
    return Request('/home/withdraw/accountlist', 'get', data)
  },
  // 设置默认账号
  getDefaultAccount (data) {
    return Request('/home/withdraw/defaultaccount', 'post', data)
  }
  

}

export default WithdrawApi